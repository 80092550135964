import React from "react";
import Layout from "../../components/layout";
import Header from "../../components/models/header";
import ModelsCard from "../../components/home/models-card";
import {Row} from "../../components/home/home.styles";
import {Title} from "../../styles/typography.styles";
import { ImageAngle, Card, CardImg, CardContent, CardTitle, CardList, CardLine} from "../../components/models/models.styles";
import BtnLink from "../../components/elements/BtnLink";
import Image from "../../components/elements/Image";
import * as DodgeJson from '../../data/dodge.json';

const Dodge = () => {
  const challenger  = DodgeJson.default.filter(item => item.model === "challenger");
  const charger = DodgeJson.default.filter(item => item.model === "charger");
  const durango = DodgeJson.default.filter(item => item.model === "durango");

  return (
      <Layout title={`Dodge`}>
        <Header img={"dodge_top_banner@2x.jpg"}/>

        <main>
          <div className={`container`}>
            <Title>WYBIERZ SWOJEGO DODGE’A</Title>

            <Row>
              <ModelsCard grid={3} title="Dodge Challenger"
                          url="#" image="/images/home/dodge_trim_challenger.jpg"
              />
              <ModelsCard grid={3} title="Dodge Charger"
                          url="#" image="/images/home/dodge_trim_charger.jpg"
              />
              <ModelsCard grid={3} title="Dodge Durango"
                          url="#" image="/images/home/dodge_trim_durango.jpg"
              />
            </Row>
          </div>

          <ImageAngle>
            <img src="/images/180307_Dodge_Challenger-SRT-Hellcat-Widebody_48.jpg" alt=""/>
          </ImageAngle>

          <div className="container">
            {challenger.map(model => (
              <Card key={model.id}>
                <CardImg>
                  <Image fileName={model.image} alt={model.title}/>
                </CardImg>
                <CardContent>
                  <CardTitle>{model.title}</CardTitle>
                  <CardLine/>

                  <CardList>
                    {model.list.map((item, i) => (
                        <li key={i}>{item}</li>
                    ))}
                  </CardList>
                </CardContent>
              </Card>
            ))}
          </div>
          <BtnLink url={'/dodge/challenger'} label={'Odkryj challengera'}/>

          <ImageAngle>
            <img src="/images/2021 Dodge Charger SRT Hellcat Redeye.jpg" alt=""/>
          </ImageAngle>

          <div className="container">
            {charger.map(model => (
              <Card key={model.id}>
                <CardImg>
                  <Image fileName={model.image} alt={model.title}/>
                </CardImg>
                <CardContent>
                  <CardTitle>{model.title}</CardTitle>
                  <CardLine/>

                  <CardList>
                    {model.list.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </CardList>
                </CardContent>
              </Card>
            ))}
          </div>
          <BtnLink url={'/dodge/charger'} label={'Odkryj chargera'}/>

          <ImageAngle>
            <img src="/images/180307_Dodge_Durango-SRT_122.jpg" alt=""/>
          </ImageAngle>

          <div className="container">
            {durango.map(model => (
              <Card key={model.id}>
                <CardImg>
                  <Image fileName={model.image} alt={model.title}/>
                </CardImg>
                <CardContent>
                  <CardTitle>{model.title}</CardTitle>
                  <CardLine/>

                  <CardList>
                    {model.list.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </CardList>
                </CardContent>
              </Card>
            ))}
          </div>
          <BtnLink url={'/dodge/durango'} label={'Odkryj durango'}/>

        </main>
      </Layout>
  )
}

export default Dodge;