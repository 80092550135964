import React from "react";
import Image from "../elements/Image";
import {ModelsHeader} from "./models.styles";

const Header = ({img, alt}) => {
  return (
      <ModelsHeader>
        <Image fileName={img} alt={alt}/>
      </ModelsHeader>
  )
}

export default Header